div.app {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  background-color: #343a41;
  border-radius: 25px;
}

@media only screen and (max-width: 480px),
  only screen and (max-device-width: 480px) {
  div.app {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #343a41;
    border-radius: 25px;
  }
}
